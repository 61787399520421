import React from "react";
import async from "./components/Async";
// Layouts
import AuthLayout from "./layouts/Auth";
import HomeLayout from "./layouts/Home";
// Guards
import AuthGuard from "./components/guards/AuthGuard";
// Auth components
import SignIn from "./pages/auth/SignIn";
import Page404 from "./pages/auth/Page404";
import EsqueciMinhaSenha from "./pages/auth/EsqueciMinhaSenha";
import AlteracaoSenha from "./pages/auth/AlteracaoSenha";
// import ConfirmacaoEmail from "./pages/auth/ConfirmacaoEmail";

// Cadastro Menu components
const LitagemItensMenus = async(() => import("./pages/menus/List"));

// Notas Fiscais DFE
const ListagemNotasFiscais = async(() => import("./pages/notas-fiscais/List"));

// Tipos de Bancos components
const ListagemBancos = async(() => import("./pages/bancos/List"));
const NovoBanco = async(() => import("./pages/bancos/New"));
const DetalheBanco = async(() => import("./pages/bancos/Detail"));

// Transferências components
const ListagemTransferencias = async(() => import("./pages/transferencias/List"));

// CategoriaProduto components
const ListagemCategoriaProduto = async(() => import("./pages/categoria-produto/List"));
const NovaCategoriaProduto = async(() => import("./pages/categoria-produto/New"));
const DetalheCategoriaProduto = async(() => import("./pages/categoria-produto/Detail"));

// FamiliaProduto components
const ListagemFamiliaProduto = async(() => import("./pages/familia-produto/List"));
const NovaFamiliaProduto = async(() => import("./pages/familia-produto/New"));
const DetalheFamiliaProduto = async(() => import("./pages/familia-produto/Detail"));

// Clientes components
const ListagemClientes = async(() => import("./pages/clientes/List"));
const NovoCliente = async(() => import("./pages/clientes/New"));
const DetalheCliente = async(() => import("./pages/clientes/Detail"));

// Tipos de Clientes components
const ListagemTipoCliente = async(() => import("./pages/tipos-cliente/List"));
const NovoTipoCliente = async(() => import("./pages/tipos-cliente/New"));
const DetalheTipoCliente = async(() => import("./pages/tipos-cliente/Detail"));

// Tipos de Pagamento components
const ListagemTipoPagamento = async(() => import("./pages/tipos-pagamento/List"));
const NovoTipoPagamento = async(() => import("./pages/tipos-pagamento/New"));
const DetalheTipoPagamento = async(() => import("./pages/tipos-pagamento/Detail"));

// Marca Produto components
const ListagemMarcaProduto = async(() => import("./pages/marca-produto/List"));
const NovoMarcaProduto = async(() => import("./pages/marca-produto/New"));
const DetalheMarcaProduto = async(() => import("./pages/marca-produto/Detail"));

// Setores Pessoa components
const ListagemSetorPessoa = async(() => import("./pages/setores-pessoa/List"));
const NovoSetorPessoa = async(() => import("./pages/setores-pessoa/New"));
const DetalheSetorPessoa = async(() => import("./pages/setores-pessoa/Detail"));

// Tipos de Cobrança components
const ListagemTipoCobranca = async(() => import("./pages/tipo-cobranca/List"));
const NovoTipoCobranca = async(() => import("./pages/tipo-cobranca/New"));
const DetalheTipoCobranca = async(() => import("./pages/tipo-cobranca/Detail"));

// Tipos de Documento components
const ListagemTipoDocumento = async(() => import("./pages/tipo-documento/List"));
const NovoTipoDocumento = async(() => import("./pages/tipo-documento/New"));
const DetalheTipoDocumento = async(() => import("./pages/tipo-documento/Detail"));

// Tipos de Fornecedores components
const ListagemTipoFornecedor = async(() => import("./pages/tipo-fornecedor/List"));
const NovoTipoFornecedor = async(() => import("./pages/tipo-fornecedor/New"));
const DetalheTipoFornecedor = async(() => import("./pages/tipo-fornecedor/Detail"));

// Tipos de Produtos components
const ListagemTipoProduto = async(() => import("./pages/tipo-produto/List"));
const NovoTipoProduto = async(() => import("./pages/tipo-produto/New"));
const DetalheTipoProduto = async(() => import("./pages/tipo-produto/Detail"));

// Planos de Contas components
const ListagemPlanoConta = async(() => import("./pages/planos-conta/List"));
const NovoPlanoConta = async(() => import("./pages/planos-conta/New"));
const DetalhePlanoConta = async(() => import("./pages/planos-conta/Detail"));

// Tipos de Natureza Operacao components
const ListagemTipoNaturezaOperacao = async(() => import("./pages/tipo-natureza-operacao/List"));
const NovoTipoNaturezaOperacao = async(() => import("./pages/tipo-natureza-operacao/New"));
const DetalheTipoNaturezaOperacao = async(() => import("./pages/tipo-natureza-operacao/Detail"));

// NCM components
const ListagemNCM = async(() => import("./pages/ncm/List"));
const NovoNCM = async(() => import("./pages/ncm/New"));
const DetalheNCM = async(() => import("./pages/ncm/Detail"));

// Rotas components
const ListagemRotas = async(() => import("./pages/rotas/List"));
const NovaRota = async(() => import("./pages/rotas/New"));
const DetalheRota = async(() => import("./pages/rotas/Detail"));

// Redes components
const ListagemRedes = async(() => import("./pages/redes/List"));
const NovaRede = async(() => import("./pages/redes/New"));
const DetalheRede = async(() => import("./pages/redes/Detail"));

// Grupos components
const ListagemGrupos = async(() => import("./pages/grupos/List"));
const NovaGrupos = async(() => import("./pages/grupos/New"));
const DetalheGrupos = async(() => import("./pages/grupos/Detail"));

// GruposAcesso components
const ListagemGruposAcesso = async(() => import("./pages/grupos-acesso/List"));
const NovaGruposAcesso = async(() => import("./pages/grupos-acesso/New"));
const DetalheGruposAcesso = async(() => import("./pages/grupos-acesso/Detail"));

// Veiculos components
const ListagemVeiculos = async(() => import("./pages/veiculos/List"));
const NovoVeiculo = async(() => import("./pages/veiculos/New"));
const DetalheVeiculo = async(() => import("./pages/veiculos/Detail"));

// Unidades components
const ListagemUnidades = async(() => import("./pages/unidades/List"));
const NovaUnidade = async(() => import("./pages/unidades/New"));
const DetalheUnidade = async(() => import("./pages/unidades/Detail"));

// Dashboard components
const Dashboard = async(() => import("./pages/dashboards"));

// Parametros components
const Parametros = async(() => import("./pages/parametros"));

// Pessoas components
const ListagemPessoas = async(() => import("./pages/pessoas/List"));
const NovaPessoa = async(() => import("./pages/pessoas/New"));
const DetalhePessoa = async(() => import("./pages/pessoas/Detail"));

// Função Pessoas components
const ListagemFuncaoPessoas = async(() => import("./pages/funcao-pessoas/List"));
const NovaFuncaoPessoa = async(() => import("./pages/funcao-pessoas/New"));
const DetalheFuncaoPessoa = async(() => import("./pages/funcao-pessoas/Detail"));

// Tipos de Produtos components
const ListagemProdutos = async(() => import("./pages/produtos/List"));
const NovoProduto = async(() => import("./pages/produtos/New"));
const DetalheProduto = async(() => import("./pages/produtos/Detail"));

//Usuarios components
const ListagemUsuarios = async(() => import("./pages/usuarios/List"));
const NovoUsuarios = async(() => import("./pages/usuarios/New"));
const DetalheUsuario = async(() => import("./pages/usuarios/Detail"));

// Fornecedores components
const ListagemFornecedores = async(() => import("./pages/fornecedores/List"));
const NovoFornecedor = async(() => import("./pages/fornecedores/New"));
const DetalheFornecedor = async(() => import("./pages/fornecedores/Detail"));

// Tabela de preços components
const ListagemTabelaPrecos = async(() => import("./pages/tabela-precos/List"));
const NovaTabelaPrecos = async(() => import("./pages/tabela-precos/New"));
const DetalheTabelaPrecos = async(() => import("./pages/tabela-precos/Detail"));

// Pedidos components
const ListagemPedidos = async(() => import("./pages/pedidos/List"));
const NovoPedido = async(() => import("./pages/pedidos/New"));
const DetalhePedido = async(() => import("./pages/pedidos/Detail"));

// Pedidos - Edição Rápida components
const ListagemPedidosEdicaoRapida = async(() => import("./pages/pedidos/edicao-rapida/List"));

// Pedidos - Separações components
const ListagemPedidosSeparacoes = async(() => import("./pages/pedidos/separacoes/List"));

// Devoluções components
const ListagemDevolucoes = async(() => import("./pages/devolucoes/List"));
const NovaDevolucao = async(() => import("./pages/devolucoes/New"));
const DetalheDevolucao = async(() => import("./pages/devolucoes/Detail"));
const EdicaoDevolucao = async(() => import("./pages/devolucoes/Edit"));

// Lançamento Financeiro components
const ListagemLancamentoFinanceiro = async(() => import("./pages/lancamentos-financeiro/List"));
const NovoLancamentoFinanceiro = async(() => import("./pages/lancamentos-financeiro/New"));
const EditarLancamentoFinanceiro = async(() => import("./pages/lancamentos-financeiro/Edit"));
const ReciboImpressao = async(() => import("./pages/lancamentos-financeiro/ReciboImpressao"));
const ReciboBeneficiarioImpressao = async(() => import("./pages/lancamentos-financeiro/ReciboBeneficiarioImpressao"));

// Ordem de Produção components
const ListagemOrdemProducao = async(() => import("./pages/ordem-producao/List"));
const NovaOrdemProducao = async(() => import("./pages/ordem-producao/New"));
const DetalheOrdemProducao = async(() => import("./pages/ordem-producao/Detail"));
const DevolucaoProducao = async(() => import("./pages/ordem-producao/DevolucaoProducao"));

// Relatórios - Spead Fiscal components
const ListagemSpeadFiscal = async(() => import("./pages/relatorios/spead-fiscal/List"));


const routes = [
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ]
  },
  {
    path: "esqueci-minha-senha",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <EsqueciMinhaSenha />,
      },
    ],
  },
  {
    path: "alteracao-senha",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <AlteracaoSenha />,
      },
    ],
  },
  // {
  //   path: "confirmacao-email",
  //   element: <AuthLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <ConfirmacaoEmail />,
  //     },
  //   ],
  // },
  {
    path: "parametros",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Parametros />,
      },
    ]
  },
  {
    path: "login",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "usuarios",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemUsuarios />,
      },
      {
        path: "novo",
        element: <NovoUsuarios />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheUsuario />,
      },
    ],
  },
  {
    path: "categorias-produto",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemCategoriaProduto />,
      },
      {
        path: "novo",
        element: <NovaCategoriaProduto />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheCategoriaProduto />,
      },
    ],
  },
  {
    path: "familias-produto",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemFamiliaProduto />,
      },
      {
        path: "novo",
        element: <NovaFamiliaProduto />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheFamiliaProduto />,
      },
    ],
  },
  {
    path: "clientes",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemClientes />,
      },
      {
        path: "novo",
        element: <NovoCliente />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheCliente />,
      },
    ],
  },
  {
    path: "tipos-cliente",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoCliente />,
      },
      {
        path: "novo",
        element: <NovoTipoCliente />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoCliente />,
      },
    ],
  },
  {
    path: "tipos-pagamento",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoPagamento />,
      },
      {
        path: "novo",
        element: <NovoTipoPagamento />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoPagamento />,
      },
    ],
  },
  {
    path: "tipo-cobranca",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoCobranca />,
      },
      {
        path: "novo",
        element: <NovoTipoCobranca />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoCobranca />,
      },
    ],
  },
  {
    path: "tipos-documento",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoDocumento />,
      },
      {
        path: "novo",
        element: <NovoTipoDocumento />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoDocumento />,
      },
    ],
  },
  {
    path: "tipo-fornecedor",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoFornecedor />,
      },
      {
        path: "novo",
        element: <NovoTipoFornecedor />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoFornecedor />,
      },
    ],
  },
  {
    path: "tipo-produto",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoProduto />,
      },
      {
        path: "novo",
        element: <NovoTipoProduto />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoProduto />,
      },
    ],
  },
  {
    path: "planos-conta",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemPlanoConta />,
      },
      {
        path: "novo",
        element: <NovoPlanoConta />,
      },
      {
        path: "detalhe/:id",
        element: <DetalhePlanoConta />,
      },
    ],
  },
  {
    path: "tipo-natureza-operacao",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTipoNaturezaOperacao />,
      },
      {
        path: "novo",
        element: <NovoTipoNaturezaOperacao />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTipoNaturezaOperacao />,
      },
    ],
  },
  {
    path: "marca-produto",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemMarcaProduto />,
      },
      {
        path: "novo",
        element: <NovoMarcaProduto />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheMarcaProduto />,
      },
    ],
  },
  {
    path: "setores-pessoa",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemSetorPessoa />,
      },
      {
        path: "novo",
        element: <NovoSetorPessoa />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheSetorPessoa />,
      },
    ],
  },
  {
    path: "rotas",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemRotas />,
      },
      {
        path: "novo",
        element: <NovaRota />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheRota />,
      },
    ],
  },
  {
    path: "redes",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemRedes />,
      },
      {
        path: "novo",
        element: <NovaRede />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheRede />,
      },
    ],
  },
  {
    path: "grupos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemGrupos />,
      },
      {
        path: "novo",
        element: <NovaGrupos />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheGrupos />,
      },
    ],
  },
  {
    path: "grupos-acesso",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemGruposAcesso />,
      },
      {
        path: "novo",
        element: <NovaGruposAcesso />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheGruposAcesso />,
      },
    ],
  },
  {
    path: "unidades-medida",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemUnidades />,
      },
      {
        path: "novo",
        element: <NovaUnidade />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheUnidade />,
      },
    ],
  },
  {
    path: "notas-fiscais",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemNotasFiscais />,
      },
    ],
  },
  {
    path: "veiculos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemVeiculos />,
      },
      {
        path: "novo",
        element: <NovoVeiculo />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheVeiculo />,
      },
    ],
  },
  {
    path: "menus",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <LitagemItensMenus />,
      },
    ],
  },
  {
    path: "fornecedores",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemFornecedores />,
      },
      {
        path: "novo",
        element: <NovoFornecedor />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheFornecedor />,
      },
    ],
  },
  {
    path: "ncm",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemNCM />,
      },
      {
        path: "novo",
        element: <NovoNCM />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheNCM />,
      },
    ],
  },
  {
    path: "pessoas",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemPessoas />,
      },
      {
        path: "novo",
        element: <NovaPessoa />,
      },
      {
        path: "detalhe/:id",
        element: <DetalhePessoa />,
      },
    ],
  },
  {
    path: "produtos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemProdutos />,
      },
      {
        path: "novo",
        element: <NovoProduto />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheProduto />,
      },
    ],
  },
  {
    path: "bancos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemBancos />,
      },
      {
        path: "novo",
        element: <NovoBanco />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheBanco />,
      },
    ],
  },
  {
    path: "funcao-pessoas",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemFuncaoPessoas />,
      },
      {
        path: "novo",
        element: <NovaFuncaoPessoa />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheFuncaoPessoa />,
      },
    ],
  },
  {
    path: "tabela-precos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTabelaPrecos />,
      },
      {
        path: "novo",
        element: <NovaTabelaPrecos />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheTabelaPrecos />,
      },
    ],
  },
  {
    path: "pedidos",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":tipoPedido",
        element: <ListagemPedidos />,
      },
      {
        path: ":tipoPedido/novo",
        element: <NovoPedido />,
      },
      {
        path: ":tipoPedido/detalhe/:id",
        element: <DetalhePedido />,
      },
      {
        path: "edicao-rapida",
        element: <ListagemPedidosEdicaoRapida />,
      },
      {
        path: "separacoes",
        element: <ListagemPedidosSeparacoes />,
      },
    ],
  },
  {
    path: "devolucoes",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ":tipoDevolucao",
        element: <ListagemDevolucoes />,
      },
      {
        path: ":tipoDevolucao/novo",
        element: <NovaDevolucao />,
      },
      {
        path: ":tipoDevolucao/detalhe/:id",
        element: <DetalheDevolucao />,
      },
      {
        path: ":tipoDevolucao/edicao/:id",
        element: <EdicaoDevolucao />,
      },
    ],
  },
  {
    path: "lancamento-financeiro",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemLancamentoFinanceiro />,
      },
      {
        path: "novo",
        element: <NovoLancamentoFinanceiro />,
      },
      {
        path: "editar/:id",
        element: <EditarLancamentoFinanceiro />,
      },
      {
        path: "imprimir/:id",
        element: <ReciboImpressao />,
      },
      {
        path: "imprimir/recibo-beneficiario",
        element: <ReciboBeneficiarioImpressao />,
      }
    ],
  },
  {
    path: "ordem-producao",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemOrdemProducao />,
      },
      {
        path: "novo",
        element: <NovaOrdemProducao />,
      },
      {
        path: "detalhe/:id",
        element: <DetalheOrdemProducao />,
      },
      {
        path: "devolucao/:id",
        element: <DevolucaoProducao />,
      },
    ],
  },
  {
    path: "transferencias",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemTransferencias />,
      },
    ],
  },
  {
    path: "spead-fiscal",
    element: (
      <AuthGuard>
        <HomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ListagemSpeadFiscal />,
      },
    ],
  },  
]

export default routes;